import { Component, OnInit, HostListener, Inject } from '@angular/core';  
import { trigger, state, transition, style, animate } from '@angular/animations';  
import { DOCUMENT } from '@angular/common';
import {Service} from '../app/services/nav-bar.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations:[ 
    trigger('fade',
    [ 
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)]),
    ]
)]
})
export class AppComponent {
  constructor(@Inject(DOCUMENT) document, public Service:Service) { }
  showNext = false;
  showPrevious = false;
  title = 'Amazing Learning Center LLC';
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //    if (window.pageYOffset > 550) {
  //      let element = document.getElementById('navbar');
  //      element.classList.add('sticky');
  //      this.showNext = true;
  //      let nextSection = document.getElementById('next-section');
  //      if (nextSection != null)
  //      {
  //       nextSection.classList.add("sticky");
  //       nextSection.style.top = element.offsetHeight + "px";
  //       this.showPrevious = true;
  //      }
  //    } else {
  //     let element = document.getElementById('navbar');
  //       element.classList.remove('sticky'); 
  //       element = document.getElementById('next-section');
  //       element.classList.remove('sticky');
  //       this.showNext = false;
  //       this.showPrevious = false;
  //    }
  // }

  onSectionChange(event)
  {
    this.Service.toggleSidebarVisibility(event);
  }
  navigateSection(isNext)
  {
    this.Service.navigateSection(isNext);
  }
}
