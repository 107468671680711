import { Component, OnInit, Input } from '@angular/core';
import {TranslateService} from '../../../services/translate.service';
import {ImageModelComponent} from '../image-model/image-model.component';
import {MatDialog} from '@angular/material';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  
  @Input() public images;
  @Input() public counter;
  selectedIndex = 0;
  constructor(public traslateService:TranslateService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.images = this.images.sort((a,b)=> this.sort(a,b));
    this.showSlides(this.slideIndex);
  }
  sort(a,b)
  {
    if (a.type < b.type)
    return -1;
    if (a.type > b.type)
    {
      return 1;
    }
    else
    return 0;
  }
  getTopFive()
  {
    var data = [];
    var substractor = 0;
    if (this.selectedIndex > 2 && this.selectedIndex < this.images.length-1)
    {
        substractor = -2;
    }
    else if (this.selectedIndex < 2)
    {
      substractor = 0;
    }
    else if (this.selectedIndex == this.images.length-1)
    {
      substractor = this.images.length - this.selectedIndex;
    }
    for (var i=0; i < 5;i++)
    {
      data.push(this.images[this.selectedIndex + (i - substractor)])
    }
    return data;
  }
  changeImage(img)
  {
    this.selectedIndex = this.images.indexOf(img);
  }
  isSelected(img)
  {
    return this.selectedIndex == this.images.indexOf(img);
  }
  next()
  {
    
    if (this.selectedIndex<this.images.length)
    {
      this.selectedIndex++;
    }
    else
    {
      this.selectedIndex = 0;
    }
  }
  prev()
  {
    if (this.selectedIndex>0)
    {
      this.selectedIndex--;
    }
    else
    {
      this.selectedIndex = this.images.length - 1;
    }
  }
  openImage(card)
  {
    var imageUlr = "";
    if (card.url.includes("_thumbail"))
    {
      imageUlr = card.url.replace('_thumbail','').replace("jpg","JPG");
    }
    else
    {
      imageUlr = card.url;      
    }
      const dialogRef = this.dialog.open(ImageModelComponent, {
        data: {imageSrc: imageUlr}
      });
  }
 slideIndex = 1;


 plusSlides(n) {
  var temp = this.slideIndex + n;
  if (temp == this.images.length)
  {
    this.slideIndex = 0;
  }
  else if (temp < 0)
  {
    this.slideIndex = this.images.length - 1;
  }
  else
  {
    this.slideIndex = temp;
  }
  
}

 currentSlide(n) {
  this.showSlides(this.slideIndex = n);
}

 showSlides(n) {
  
  
}
}
