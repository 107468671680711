export interface LinksStructure {
        label:string,
        title:string,
        showing:boolean   
}
export interface GalleryOptionsStructure {
    label:string, 
    checked:boolean,
    id:number
}
export interface DocumentsStructure {
    title:string,
    description:string,
    imgUrl:string,
    cols:number,
    rows:number,
    documentUrl:string,
    preview:boolean,
    id:number
}
export interface LocationStructure {
     title:string,
     mapLink:string,
     description:string[]
}
export interface ContactUsStructure {
    message:string, 
    data:string,
    icon:string
}
export interface DataStructure {
    navbar:{
        links: LinksStructure[],
        language:string,
        title:string
    },
    home: {
        title:string,
        subtitle:string,
        missionStatement:string,
        meetTheTeacherTitle:string,
        meetTheTeacherBody:string,
        dayCareGoalsTitle:string
        dayCareGoalsBody:string,
        tourLabel:string,
        offerLabel:string,
        offerList:string[],
        hoursLabel:string,
        hours:string[]
    }
    location:{
        title:string,
        subtitle:string,
        description:string,
        cardtitle:string,
        instructions:LocationStructure[]
    },
    gallery: {
        title:string,
        options:GalleryOptionsStructure[]
    },
    documents: {
        title:string,
        downloadText:string,
        previewText:string,
        document:DocumentsStructure[]
    },
    contactUs: {
        title:string,
        contactData: ContactUsStructure[],
        emailLabel:string,
        phoneLabel:string,
        messageLabel:string,
        nameLabel:string,
        required:string,
        nameRequired:string,
        validEmail:string,
        emailRequired:string,
        messageRequired:string,
        formSubmited:string

    }
    footer:{
        title:string,
        copyright:string,
        createdByLabel:string,
        createdByName:string
    }
}

export const english:DataStructure =  {
    navbar:{
        links:[{label:'home',title:'Home',showing:true},
        {label:'location',title:'Location',showing:false},
        {label:'gallery',title:"Gallery",showing:false},
        {label:'documents',title:'Documents',showing:false},
        {label:'contact-us',title:"Contact Us",showing:false}
      ],
        language:"Language",
        title:"Amazing Learning Center LLC"
    },
    home: {
        title:"Welcome To Our Day Care",
        subtitle:"A different approach",
        missionStatement:`<p>Because we know that the emotional, social and physical development of young children has a direct effect on their overall development and on the adult they will become. Because we understand that Early Childhood Education can play a critical role during this important developmental period, we offer a child care guided by those more integrating and positive curriculums to achieve an instructive, happy, safe and healthy environment.</p>

        <p>Children who have positive educational early childhood experiences are more likely to experience school success.</p>
        
        <p>Our mission is to work for a child care that will provide the necessary tools for a successful school experience.</p>
        
        <p><b>AMAZING LEARNING CENTER LLC.</b></p>
        
        <p><b>A DIFFERENT APPROACH</b></p>`,
        meetTheTeacherTitle:"Meet the teacher",
        meetTheTeacherBody:"I have dedicated my whole life to work with children, teenagers, and young people. I graduated in Cuba in 1987 with a Bachelor of Education. In 2009, upon arriving in the United States, I specialized in Early Education in Miami Dade College. My experience has been enriched by work in different child care centers. In those centers, I have acquired skills with the use of various educational methods. Programs include: Creative Curriculum, High Scope, Montessori, Reggio Emilia, among others. These experiences allowed me work with all ages that comprise Early Childhood care—from one year to VPK students, as well as mixed classrooms.",
        dayCareGoalsTitle:"Our goals",
        dayCareGoalsBody:"Our fundamental goal is to offer high-quality child care that guarantees the well-being of the child and his/her preparation for school. We promote an environment that develops both the physical and mental abilities of children. Through the use of integrating curriculums, play, instructive activities, positive interpersonal relationships, the promotion of independence and creativity we will be providing children the tools for a successful school experience.",
        tourLabel:"Video Tour",
        offerLabel:"Our center will offer:",
        offerList:["Amazing Learning Center LLC is a child care center licensed by the children and family department. License # L11MD0307.",
        "Our center follows the safety and health standards determined by the Department of Children and Family in its Administrative Code.",
        "The staff has experience working with children of all ages. We have the knowledge that will enhance the cognitive, emotional and physical development of the child in each stage.",
        "The daily routine follows a schedule that allows the linking of all those activities that the child needs for their well-being.",
        "We provide classes that will give your child the necessary tools to learn to read, write and all those motor skills necessary for their future school life.",
        "Our center is equipped with all the necessary tools to facilitate the children’s learning and creativity. We have crayons, paper, books, pencil, etc. All materials that parents don’t have to worry about buying.",
        "The audiovisual media will only be used as a teaching tool, not as entertainment.",
        "Children will receive 4 meals, breakfast, snacks, lunch and afternoon snack.",
        "We have created a variety of different menus compose of healthy foods to guarantee the healthy development of the children. As a parent you will have access to these menus, to review them, and inform us of any child allergies so we can change our menu around your children.",
        "Our dining area is fully equipped with, plastic cups, and bottled waters.",
        "Children will be able to take a nap in our comfortable stackable daycare cots.",
        "Our center has a safe, and secure playground area where children will be able to play.",
        "All our rooms are spacious, comfortable with excellent ventilation and natural lighting.",
        "We will only close 6 days in the year.",
        "With the weekly payment, the parents are covering all the necessary elements for the care of the child and the activities that are carried out in our center during the week. This includes days of special celebrations such as Valentine's Day, Mother’s Day, Earth Day, St. Patrick Day, etc. No additional payment or monetary assistance will be requested."],
        hoursLabel: "Hours",
        hours:["Monday: 7:00 AM to 6:00 PM",
        "Tuesday: 7:00 AM to 6:00 PM",
        "Wednesday: 7:00 AM to 6:00 PM",
        "Thursday: 7:00 AM to 6:00 PM",
        "Friday: 7:00 AM to 6:00 PM",
            ]
    },
    location:{
        title:"How To Find Us?",
        subtitle:"A place close to everything important",
        description:"<p>Amazing Learning Center LLC, a quality child care located less than a mile from exit NW 22 off Expressway 112.</p> <p>We are 10 minutes from Downtown, 5 minutes from Miami International Airport, and 20 minutes from Miami Beach.</p> ",
        cardtitle:"Location",
        instructions:[{
            title:"From Miami Beach",
            mapLink:"https://goo.gl/maps/UtD3psGHXGwPwUBh8",
            description:["Get on I-195 W from 17th St and Alton Rd",
                         "Continue on I-195 W to NW 41st St. Take the exit toward NW 22 Ave/Metrorail from FL-112 W",
                         "Continue on 41st St. Drive to NW 21st Ave"]
        },
        {
            title:"From Downtown Miami",
            mapLink:"https://goo.gl/maps/n5heRs8fz2XCgRPy9",
            description:["Get on I-95 N",
                         "Continue on I-95 N. Take Exit 4B to NW 41st St. Take the exit toward NW 22 Ave/Metrorail from FL-112 W",
                         "Continue on 41st St. Drive to NW 21st Ave"]
        },
        {
            title:"From Kendall",
            mapLink:"https://goo.gl/maps/BNqjg93v95zMuRc48",
            description:["Get on FL-874 N from Killian Pkwy",
                         "Continue on FL-874 N. Take FL-826 N and FL-836 to FL-953 N/NW 42nd Ave/S Le Jeune Rd in Miami. Take the FL-953 N/Le Jeune Rd exit from FL-836",
                         "Get on FL-112 E in Hialeah",
                         "Follow FL-112 E to NW 22nd Ave. Take the NW 22nd Ave exit from FL-112 E",
                         "Follow NW 22nd Ave and NW 43rd St to NW 21st Ave in Brownsville"]
        },
        {
            title:"From Hialeah",
            mapLink:"https://goo.gl/maps/g5mZQ7VF4WkuXTt18",
            description:["Take E 38th St to E 8th Ave/N Le Jeune Rd",
                         "Follow E 8th Ave/N Le Jeune Rd and FL-944 E/NW 54th St to NW 22nd Ave in Brownsville",
                         "Continue on NW 22nd Ave. Drive to NW 21st Ave"]
        }]
    },
    gallery: {
        title:"Gallery",
        options:[{label:'Classroom', checked:true,id:1},
        {label:'Dinning Room', checked:true,id:2},
        {label:'Playground', checked:true,id:3}]
    },
    documents: {
        title:"Documents",
        downloadText:"Download",
        previewText:"Preview",
        document:[{id:1,title:"Family Day Care Contract",cols:1,rows:1,description:"This document presents the disciplinary rules of our center, as well as the different situations that could end the childcare contract signed between the parents and the provider. This document is presented as part of the regulations established by the Department of Children and Family of Florida.",
        preview:false,documentUrl:"assets/documents/contract.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:2,title:"Disciplinary and Expulsion Form",cols:1,rows:1,description:"This document presents the disciplinary rules of our center, as well as the different situations that could end the childcare contract signed between the parents and the provider. This document is presented as part of the regulations established by the Department of Children and Family of Florida.",
        preview:false,documentUrl:"assets/documents/disciplinary.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:3,title:"First week menu",cols:1,rows:1,description:"You want your child to eat well—and be happy, healthy, and active. We couldn’t agree more. That’s why we provide nutritious meals and snacks to meet the needs of rapidly growing bodies and minds.",
        preview:false,documentUrl:"assets/documents/menu_1.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:4,title:"Second week menu",cols:1,rows:1,description:"You want your child to eat well—and be happy, healthy, and active. We couldn’t agree more. That’s why we provide nutritious meals and snacks to meet the needs of rapidly growing bodies and minds.",
        preview:false,documentUrl:"assets/documents/menu_2.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:5,title:"Third week menu",cols:1,rows:1,description:"You want your child to eat well—and be happy, healthy, and active. We couldn’t agree more. That’s why we provide nutritious meals and snacks to meet the needs of rapidly growing bodies and minds.",
        preview:false,documentUrl:"assets/documents/menu_3.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:6,title:"Fourth week menu",cols:1,rows:1,description:"You want your child to eat well—and be happy, healthy, and active. We couldn’t agree more. That’s why we provide nutritious meals and snacks to meet the needs of rapidly growing bodies and minds.",
        preview:false,documentUrl:"assets/documents/menu_4.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:7,title:"Fifth week menu",cols:1,rows:1,description:"You want your child to eat well—and be happy, healthy, and active. We couldn’t agree more. That’s why we provide nutritious meals and snacks to meet the needs of rapidly growing bodies and minds.",
        preview:false,documentUrl:"assets/documents/menu_5.pdf",imgUrl:"assets/images/logo/pdf.png"}]
    },
    contactUs: {
        title:"Contact Us",
        contactData:[{message:"Email us", data:"amparo001@hotmail.com",icon:"email"},
        {message:"Call us", data:"(786)-519-7611",icon:"phone"},
        {message:"Address", data:"4290 NW 21 Ave, Miami, FL, 33142",icon:"location_on"}],
        emailLabel:"Email",
        messageLabel:"Message",
        nameLabel:"Name",
        phoneLabel:"Phone",
        emailRequired:"Email is",
        messageRequired: "A message is",
        nameRequired: "Your name is",
        required: " required",
        validEmail:"Please enter a valid email address",
        formSubmited:"Thank you for contacting us, we will contact you as soon as possible"
    },
    footer:{
        title:"Amazing Learning Center LLC",
        copyright:"DCF License #: L11MD0307",
        createdByLabel:"Created by",
        createdByName:"Darian Mendez"
    }
}
export const spanish:DataStructure =  {
    navbar:{
        links:[{label:'home',title:'Casa',showing:true},
        {label:'location',title:'Localización',showing:false},
        {label:'gallery',title:"Galería",showing:false},
        {label:'documents',title:'Documentos',showing:false},
        {label:'contact-us',title:"Contáctenos",showing:false}
      ],
        language:"Idioma",
        title:"Amazing Learning Center LLC"
    },
    home: {
        title:"Bienvenido A Nuestro Day Care",
        subtitle:"Un enfoque diferente",
        missionStatement:`<p>Porque sabemos que el desarrollo emocional, social y físico de los niños pequeños tiene un efecto directo en su desarrollo general y en el adulto en el que se convertirán. Porque entendemos que la educación en la primera infancia puede desempeñar un papel crítico durante este importante período de desarrollo; nosotros ofrecemos un cuidado infantil guiado por aquellos programas más integradores y positivos, para lograr un ambiente educativo, feliz, seguro y saludable.</p>

        <p>Los niños que tienen experiencias educativas positivas en la primera infancia tienen más probabilidades de experimentar el éxito escolar.</p>
        
        <p>Nuestra misión es trabajar para un cuidado infantil que proporcione las herramientas necesarias para una experiencia escolar exitosa.</p>
        
        <p><b>Amazing Learning Center LLC</b></p>.
        
        <p><b>UN ENFOQUE DIFERENTE</b></p>`,
        meetTheTeacherTitle:"Conozca a la maestra",
        meetTheTeacherBody:"Graduada en Cuba desde 1987 con una Licenciatura en Educación, he dedicado toda mi vida al trabajo con niños, adolescentes y jóvenes. En el año 2009, llego a Estados Unidos y en Miami Dade College realizo estudios para la especialización en Educación Temprana. Mi experiencia se ha visto enriquecida con el trabajo en diferentes centros de cuidado infantil. En estos centros he adquirido habilidades en la utilización de diversos currículos como, Curriculum Creativo, High Scope, Montessori, Regio Emilia entre otros. Un aspecto importante en dicha experiencia es la posibilidad que tuve de trabajar con todas las edades que comprenden el cuidado Infantil Temprano, desde un año hasta estudiantes de VPK, así como aulas mixtas.",
        dayCareGoalsTitle:"Nuestras metas",
        dayCareGoalsBody:"Nuestra meta fundamental es ofrecer un cuidado infantil de alta calidad que garantice el bienestar del niño y su preparación para la vida escolar. Potenciamos un ambiente que desarrolle las capacidades físicas y mentales de los niños. Con el uso de currículos integradores y a través del juego, las actividades instructivas, las positivas relaciones interpersonales, la promoción de la independencia y la creatividad estaremos dando a nuestros niños las herramientas para una exitosa experiencia escolar.",
        tourLabel: "Video Tour",
        offerLabel:"Nuestro centro ofrecerá",
        offerList:["Amazing Learning Center LLC es un centro licenciado por el Departamento De Niños y Familia. Licencia #  L11MD0307.",
            "Nuestro centro sigue los estándares de salud y seguridad establecidos por el Departamento De Niños y Familia en su Código Administrativo.",
            "El personal tiene experiencia en el trabajo con niños de todas las edades. Conocemos los recursos que potenciarán el desarrollo cognitivo, emocional y físico del niño en cada etapa.",
            "La rutina diaria sigue un horario que permite la vinculación de todas las actividades que el niño necesita para su buen desarrollo.",
            "Con nuestras clases daremos a su hijo las herramientas necesarias para aprender a leer, escribir y todas aquellas  habilidades motoras necesarias para su futura vida escolar.",
            "Nuestro centro está equipado con todo aquello que es necesario para que los niños aprendan y desarrollen su creatividad. Nosotros contamos con materiales como crayones, lápices, libros etc. Estos materiales no serán responsabilidad de los padres.",
            "Los medios audiovisuales serán utilizados solamente como herramientas para el aprendizaje, no para entretenimiento.", 
            "Los niños recibirán 4 comidas en el día, desayuno, merienda, lunch y una merienda final a la tarde.",
            "Hemos creado un menú que cambia cada semana, con alimentos nutritivos que garantizan el crecimiento saludable de los niños. Los padres pueden solicitar este menú, revisar el mismo y darnos a conocer de alguna alergia que su niño tenga para poder hacer los cambios necesarios.", 
            "Nuestro comedor está equipado con platos,cubiertos,  vasos desechables, así como botellas plásticas de agua para garantizar una mejor higiene.", 
            "Los niños podrán dormir la siesta en cómodos  catres individuales.", 
            "El área de juego exterior es confortable y segura para el juego de los niños.", 
            "Todas nuestras habitaciones son espaciosas, agradables, con excelente ventilación y luz natural.",
            "Nuestro centro solamente cerrara 6 días en el año.",  
            "Con el pago semanal los padres estarán cubriendo todo los elementos necesarios para el cuidado de su niño y las actividades que se realizarán en el centro durante la semana. Esto incluye celebraciones especiales como: Día de San Valentín, Día de las Madres, Día de la Tierra, Día de San Patrick. No se pedirán pagos ni ayuda monetaria extra."],
            hoursLabel:"Horario",
            hours:["Lunes: 7:00 AM to 6:00 PM",
            "Martes: 7:00 AM to 6:00 PM",
            "Miércoles: 7:00 AM to 6:00 PM",
            "Jueves: 7:00 AM to 6:00 PM",
            "Viernes: 7:00 AM to 6:00 PM",
                ]
    },
    location:{
        title:"Como Encontrarnos",
        subtitle:"Un lugar cercano a todo lo importante",
        description:"<p>Amazing Learning Center LLC, un cuidado infantil de alta calidad localizado a menos de una milla de la salida número 22 del Expressway 112.</p><p>A 10 minutos del Downtown, 5 minutos del Aeropuerto de Miami y 20 minutos de Miami Beach.</p>",
        cardtitle:"Localización",
        instructions:[{
            title:"Desde Miami Beach",
            mapLink:"https://goo.gl/maps/rSmgmweWyupPp1uQ6",
            description:["Accede a I-195 W desde 17th St y Alton Rd.",
                         "Continúa por I-195 W hacia NW 41st St. Toma la salida en dirección a NW 22 Ave/Metrorail de FL-112 W.",
                         "Continúa por NW 41st St. Conduce hacia NW 21st Ave."]
        },
        {
            title:"Desde Downtown Miami",
            mapLink:"https://goo.gl/maps/PNtM92YMgY7wYGNE6",
            description:["Accede a I-95 N",
                         "Continúa por I-95 N. Toma Salida 4B hacia NW 41st St. Toma la salida en dirección a NW 22 Ave/Metrorail desde FL-112 W.",
                         "Continúa por NW 41st St. Conduce hacia NW 21st Ave."]
        },
        {
            title:"Desde Kendall",
            mapLink:"https://goo.gl/maps/3aZxqdVCbGGAmgqU6",
            description:["Accede a FL-874 N desde Killian Pkwy.",
                         "Continúa por FL-874 N. Toma FL-826 N y FL-836 hacia FL-953 N/NW 42nd Ave/S Le Jeune Rd en Miami. Toma la salida FL-953 N/Le Jeune Rd de FL-836.",
                         "Accede a FL-112 E en Hialeah.",
                         "Sigue por FL-112 E hacia NW 22nd Ave. Toma la salida NW 22nd Ave de FL-112 E.",
                         "Sigue por NW 22nd Ave y NW 43rd St hacia NW 21st Ave en Brownsville."]
        },
        {
            title:"Desde Hialeah",
            mapLink:"https://goo.gl/maps/rEa7RVLExTpfY1Gk6",
            description:["Toma E 38th St hacia E 8th Ave/N Le Jeune Rd.",
                         "Sigue por E 8th Ave/N Le Jeune Rd y FL-944 E/NW 54th St hacia NW 22nd Ave en Brownsville.",
                         "Continúa por NW 22nd Ave. Conduce hacia NW 21st Ave."]
        }]
    },
    gallery: {
        title:"Galería",
        options:[{label:'Salón', checked:true,id:1},
        {label:'Comedor', checked:true,id:2},
        {label:'Parque', checked:true,id:3}]
    },
    documents: {
        title:"Documentos",
        downloadText:"Descargar",
        previewText:"Ver en linea",
        document:[{id:1,title:"Contrato Del Day Care",cols:1,rows:1,description:"En este documento se presentan las normas disciplinarias de nuestro centro, así como las diferentes situaciones que podrían poner fin al contrato de cuidado infantil firmado entre los padres y el proveedor. Este documento se presenta como parte de las normativas establecidas por el Departamento de Niños y Familia de la Florida.",
        preview:false,documentUrl:"assets/documents/contract_spanish.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:2,title:"Formulario Disciplinario Y De Expulsión",cols:1,rows:1,description:"En este documento se presentan las normas disciplinarias de nuestro centro, así como las diferentes situaciones que podrían poner fin al contrato de cuidado infantil firmado entre los padres y el proveedor. Este documento se presenta como parte de las normativas establecidas por el Departamento de Niños y Familia de la Florida.",
        preview:false,documentUrl:"assets/documents/disciplinary_spanish.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:3,title:"Menú de la primera semana",cols:1,rows:1,description:"Usted desea que su hijo coma bien y sea feliz, saludable y activo. No podríamos estar más de acuerdo. Es por eso que ofrecemos comidas y refrigerios nutritivos para satisfacer las necesidades de los cuerpos y las mentes en un rápido crecimiento.",
        preview:false,documentUrl:"assets/documents/menu_1.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:4,title:"Menú de la segunda semana",cols:1,rows:1,description:"Usted desea que su hijo coma bien y sea feliz, saludable y activo. No podríamos estar más de acuerdo. Es por eso que ofrecemos comidas y refrigerios nutritivos para satisfacer las necesidades de los cuerpos y las mentes en un rápido crecimiento.",
        preview:false,documentUrl:"assets/documents/menu_2.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:5,title:"Menú de la tercera semana",cols:1,rows:1,description:"Usted desea que su hijo coma bien y sea feliz, saludable y activo. No podríamos estar más de acuerdo. Es por eso que ofrecemos comidas y refrigerios nutritivos para satisfacer las necesidades de los cuerpos y las mentes en un rápido crecimiento.",
        preview:false,documentUrl:"assets/documents/menu_3.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:6,title:"Menú de la cuarta semana",cols:1,rows:1,description:"Usted desea que su hijo coma bien y sea feliz, saludable y activo. No podríamos estar más de acuerdo. Es por eso que ofrecemos comidas y refrigerios nutritivos para satisfacer las necesidades de los cuerpos y las mentes en un rápido crecimiento.",
        preview:false,documentUrl:"assets/documents/menu_4.pdf",imgUrl:"assets/images/logo/pdf.png"},
        {id:7,title:"Menú de la quinta semana",cols:1,rows:1,description:"Usted desea que su hijo coma bien y sea feliz, saludable y activo. No podríamos estar más de acuerdo. Es por eso que ofrecemos comidas y refrigerios nutritivos para satisfacer las necesidades de los cuerpos y las mentes en un rápido crecimiento.",
        preview:false,documentUrl:"assets/documents/menu_5.pdf",imgUrl:"assets/images/logo/pdf.png"}]
    },
    contactUs: {
        title:"Contáctenos",
        emailLabel:"Correo",
        messageLabel:"Mensaje",
        nameLabel:"Nombre",
        phoneLabel:"Teléfono",
        emailRequired:"Un correo es",
        messageRequired:"Un mensaje es",
        nameRequired:"Un nombre es",
        required:" necesario",
        validEmail:"Por favor, introduce una dirección de correo electrónico válida",
        contactData:[{message:"Envíenos un correo electrónico", data:"amparo001@hotmail.com",icon:"email"},{message:"Llamanos", data:"(786)-519-7611",icon:"phone"},{message:"Dirección", data:"4290 NW 21 Ave, Miami, FL, 33142",icon:"location_on"}],
        formSubmited:"Gracias por contactarnos, nos pondremos en contacto con usted lo antes posible."
    },
    footer:{
        title:"Amazing Learning Center LLC",
        copyright:"DCF Licencia #: L11MD0307",
        createdByLabel:"Creado por",
        createdByName:"Darian Mendez"
    }
}