import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';
import {Service} from './nav-bar.service';
@Directive({
    selector: '[scrollSpy]'
})
export class ScrollSpyDirective {
    @Input() public spiedTags = [];
    @Output() public sectionChange = new EventEmitter<string>();
    private currentSection: string;

    constructor(private _el: ElementRef,public service:Service) {}
    hideSectionNav (section)
    {
        if (section != null)
        {
            section.classList.replace("show","hide");
        }
    }
    showSectionNav(section)
    {
        if (section != null)
        {
            section.classList.replace("hide","show");
        }
    }
    @HostListener('scroll', ['$event'])
    onScroll(event: any) {
        var headerHeight = document.getElementById('header').offsetHeight - document.getElementById('navbar').offsetHeight;
        // var nextSection = document.getElementById('next-section');
        // var previousSection =  document.getElementById("previous-section");
        if (event.target.scrollTop > headerHeight) {
            let element = document.getElementById('navbar');
            element.classList.add('sticky');
            // if (nextSection != null)
            // {
            //  //this.showSectionNav(nextSection);
            // //  nextSection.classList.add("sticky");
            // //  nextSection.style.top = element.offsetHeight + "px";
        
            // }
          } else {
           let element = document.getElementById('navbar');
             element.classList.remove('sticky'); 
             element = document.getElementById('next-section');
             element.classList.remove('sticky');
            //  this.hideSectionNav(nextSection);
            //  this.hideSectionNav(previousSection);      
          }
        let currentSection: string;
        var children = this._el.nativeElement.children[1].children;
        const scrollTop = event.target.scrollTop;
        const parentOffset = event.target.offsetTop;
        let atBottom = (event.target.scrollHeight - event.target.scrollTop) - 100 <= event.target.clientHeight
        if (atBottom && !this.service.allPageShowing())
        {
            var temp = this.service.getNextSectionToDisplay();
            temp.showing = true;
            this.sectionChange.emit(temp.label);
        }
        else if (atBottom && this.service.allPageShowing())
        {
            this.currentSection = "contact-us";
            this.sectionChange.emit("contact-us");
        }
        if (this.service.allPageShowing())
        {
            for (let i = 0; i < children.length; i++) {
                const element = children[i];
                if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
                    if ((element.offsetTop - parentOffset) <= scrollTop) {
                        currentSection = element.id;
                        // if (currentSection == "location" && previousSection != null)
                        // {
                        //     this.showSectionNav(previousSection);
                        // }
                        // if (currentSection == "contact-us" && nextSection != null)
                        // {
                        //     this.hideSectionNav(nextSection);
                        // }
                            if (currentSection == "gallery")
                            {
                                var checkboxes = document.getElementById("checkboxes");
                                checkboxes.classList.add("sticky");
                                checkboxes.style.top = document.getElementById('navbar').offsetHeight + "px";
                            }
                            else
                            {
                                var checkboxes = document.getElementById("checkboxes");
                                checkboxes.classList.remove("sticky");
                            }
                            if (currentSection == "home")
                            {
                                var gif = document.getElementById("teacher");
                                gif.style.visibility = 'visible';
                            }
                            else
                            {
                                var gif = document.getElementById("teacher");
                                gif.style.visibility = 'hidden';
                            }
                    }
                }
            }
            if (currentSection !== this.currentSection) {
                this.currentSection = currentSection;
                this.sectionChange.emit(this.currentSection);
            }
        }

    }

}
