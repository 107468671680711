import { Component,Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import {ROUTE_ANIMATIONS_ELEMENTS} from '../../../animations/route.animations';
import {TranslateService} from '../../../services/translate.service';
import {ImageModelComponent} from '../image-model/image-model.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-gallery-photos',
  templateUrl: './gallery-photos.component.html',
  styleUrls: ['./gallery-photos.component.css']
})
export class GalleryPhotosComponent {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  /** Based on the screen size, switch from standard to one column per row */
  
    copyImages = [];
    @Input() counter = 0;
    @Input() public images;
    cols = 4;
    rows="2:1"
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches)
      {
        this.cols = 2;
        this.rows = "1:1"
      }
      else
      {
        this.cols = 4;
        this.rows = "2:1"
      }
      var data = [];
      this.images.forEach(image=> {
        var col = image.cols;
        var row = image.rows;
        if (matches)
        {
          col = 1;
          row = 1;
        }
        data.push({title:image.title,cols:col,rows:row,url:image.url,type:image.type});
      });
      return data.sort((a,b)=> this.sort(a,b));
      // if (matches) {
      //   this.copyImages =  this.copyImages.map((d)=> {
      //     d.cols = 1;
      //     d.rows = 1;  
      //     return d;
      //   });
      // }
      // else
      // {
      //   this.copyImages = Object.assign([],this.images);
      // }

      // return this.copyImages.sort((a,b)=> this.sort(a,b));
    })
  );
  sort(a,b)
  {
    if (a.type < b.type)
    return -1;
    if (a.type > b.type)
    {
      return 1;
    }
    else
    return 0;
  }
  options = [{label:'Classroom', checked:true,id:1},
  {label:'Dinning Room', checked:true,id:2},
  {label:'Playground', checked:true,id:3}]
  constructor(public breakpointObserver: BreakpointObserver,
              public traslateService:TranslateService,
              public dialog: MatDialog) {}

  changeOption(option)
  {
   this.counter++; 
  }
  openImage(card)
  {
    var imageUlr = card.url;
      const dialogRef = this.dialog.open(ImageModelComponent, {
        data: {imageSrc: imageUlr}
      });
  }
}
