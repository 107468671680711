import { Component, OnInit,ViewChild,ElementRef,OnDestroy } from '@angular/core';
import {ROUTE_ANIMATIONS_ELEMENTS} from '../../animations/route.animations';
import {TranslateService} from '../../services/translate.service';
import {fade} from '../../services/animations';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title="Welcome to our Day Care";
  subtitle="Let us take care of your kid";
  tour = [{title: "Salon", backgroundURL:"/assets/images/daycare/salon/salon_widget.jpg"},
  {title: "Kitchen", backgroundURL:""},
  {title: "Patio", backgroundURL:""}
];

description = "This is the description of the daycare";
routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
videoSource = "assets/videos/english.mov";
parallaxImages = ["assets/images/logo/home_1.jpg",
                  "assets/images/logo/home_2.jpg",
                  "assets/images/logo/home_3.jpg"];
parallaxImageSelected = 0;
interval;
@ViewChild('videoPlayer') videoplayer: ElementRef;


  constructor(public translateService:TranslateService) { }

  ngOnInit() {
    this.interval = setInterval(()=> {
      if (this.parallaxImageSelected + 1 == this.parallaxImages.length)
      {
        this.parallaxImageSelected = 0;
      }
      else
      {
        this.parallaxImageSelected++;
      }
    },10000);
  }
  ngOnDestroy () {
    this.interval.clearInterval();
  }
  getImageUrl ()
  {
    return this.parallaxImages[this.parallaxImageSelected];
  }
  show(image)
  {
    if (this.parallaxImages.indexOf(image)==this.parallaxImageSelected)
    {
      return 'in';
    }
    else
    {
      return 'out';
    }
  }
  toggleVideo(event: any) {    
    const video: HTMLVideoElement = this.videoplayer.nativeElement;
    video.play();
  }
  
}
