import { Component, OnInit } from '@angular/core';
import {Service} from '../../services/nav-bar.service';
import {TranslateService} from '../../services/translate.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  title;
  test = "<p>This is a test</p>";
  constructor(public Service:Service,
              public translateService:TranslateService) {
    
   }

  ngOnInit() {
  }

}
