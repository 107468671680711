import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {english,spanish,DataStructure} from './data';
 export enum  Language {
  English =1,
  Spanish =2
}
@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  //App title
  private Title = "Amazing Learning Center LLC";
  languageKey = "language";
  //language
  private selectedLanguage = this.getDefaultLanguage();
  selectedLanguageChange: Subject<number> = new Subject<number>();
  private languages = [{text:"English",value:Language.English,src:'assets/images/logo/english.png'},
               {text:"Español",value:Language.Spanish,src:'assets/images/logo/spain.png'}];
   private data;
    constructor()  {
        this.setData(this.selectedLanguage);
    }
    getDefaultLanguage ()
    {
        var language = sessionStorage.getItem(this.languageKey);
        if (language)
        {
            switch(language)
            {
                case "1":
                    return Language.English;
                break;
                case "2":
                    return Language.Spanish;
                break;
            }
        }
        else
            return Language.English;
    }
    getLanguages(){
        return this.languages;
    }
    getLanguageData ()
    {
        return this.languages.filter(l=> l.value == this.selectedLanguage)[0];
    }
    getLanguageSelected ()
    {
        return this.selectedLanguage;
    }
    getData () :DataStructure
    {
        return this.data;
    }
    setData(language:number)
    {
        sessionStorage.setItem(this.languageKey,language+"");
        switch(language)
        {
            case Language.English:
                this.data = english;
            break;
            case Language.Spanish:
                this.data = spanish;
            break;
        }
        this.selectedLanguage = language;
    }
    toggleLanguage (value:number)
    {
      this.selectedLanguageChange.next(value);
      this.setData(value);
    }
  



}
