import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
 export enum  Language {
  English =1,
  Spanish =2
}
@Injectable({
  providedIn: 'root'
})
export class Service {

  //App title
  private Title = "Amazing Learning Center LLC";
  //Sections

  //navbar
  private selectedNavOption= "home";
  selectedNavOptionChange: Subject<string> = new Subject<string>();
  links = [{label:'home',title:'Home',showing:true},
  {label:'location',title:'Location',showing:false},
  {label:'gallery',title:"Gallery",showing:false},
  {label:'documents',title:'Documents',showing:false},
  {label:'contact-us',title:"Contact Us",showing:false}
];

  //language


    constructor()  {

    }
    getAppTitle ()
    {
      return this.Title;
    }
    toggleSidebarVisibility(value:string) {
        this.selectedNavOptionChange.next(value);
        this.selectedNavOption = value;
    }
    getSelectedOption ()
    {
      return this.selectedNavOption;
    }
    getNextSectionToDisplay ()
    {
      var index = 0;
      this.links.forEach(link=> {
        if (link.showing)
        {
          index = index + 1;
        }
      });
      return this.links[index];
    }
    shouldShowSection (section)
    {
      return this.links.filter(x=> x.label == section)[0].showing;
    }
    allPageShowing ()
    {
      return !(this.links.filter(x=> x.showing == false).length > 0);
    }
    navigateSection(isNext) {
      var link = this.links.filter(l=> l.label == this.selectedNavOption);
      if (link.length > 0)
      {
        var index = this.links.indexOf(link[0]);
        if (isNext)
        {
          if (index+1 < this.links.length)
          {
            index = index + 1
          }
        }
        else 
        {
          if (index - 1 >= 0)
          {
            index = index - 1;
          }
        }
        this.toggleSidebarVisibility(this.links[index].label);
        this.scrollTo(this.links[index].label);
      }
    }
    scrollTo(id)
    {
        
      if (this.links.filter(link=> link.label == id)[0].showing == false)
      {
        var counter = 0;
        while (this.links[counter].label != id)
        {
          this.links[counter].showing = true;
          counter = counter + 1;
        }
        this.links[counter].showing = true;
      }
      setTimeout(()=> {
        const element = document.querySelector("#" + id);
        if (element) 
        {         
           element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },100)

    }
  



}
