import { Component, OnInit, EventEmitter,Output } from '@angular/core';
import {Service,Language} from '../../services/nav-bar.service';
import {TranslateService} from '../../services/translate.service';
@Component({
  selector: 'app-main-bar',
  templateUrl: './main-bar.component.html',
  styleUrls: ['./main-bar.component.css']
})
export class MainBarComponent implements OnInit {
  title;
  links = []
  activeLink;
  languages = [];
  selectedLanguage = "";
  @Output () emit = new EventEmitter();
  constructor(public service:Service,
              public translateService:TranslateService) { 
    this.title = this.service.getAppTitle();
  }

  ngOnInit() {
    this.languages = this.translateService.getLanguages();
    this.links = this.translateService.getData().navbar.links;
    this.selectedLanguage = this.translateService.getLanguageData().src;
    this.activeLink = this.links[0];
    this.service.selectedNavOptionChange.subscribe((value)=> {
        var link = this.translateService.getData().navbar.links.filter(l=> l.label == value);
        if (link.length > 0)
        {
          this.activeLink = link[0];
        }
    });


  }
  scrollTo(link)
  {
    this.activeLink = link;
    //Scroll to section
    this.service.scrollTo(link.label);
  }
  changeLanguage(language)
  {
    this.selectedLanguage = language.src;
    this.translateService.toggleLanguage(language.value);
    this.service.toggleSidebarVisibility(this.activeLink.label);
  }
  scrollTop(){
    const element = document.getElementsByClassName("nav-top")[0] as HTMLElement;
    if (element) 
    {         
       element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
}
