import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

  constructor() { }
  facebookUrl = "https://www.facebook.com/InsuaFamilyDayCareHome";
  instagramUrl = "https://www.instagram.com/insua_family_daycare_home";
  ngOnInit() {
  }
  navigate(option)
  {
    var url = "";
    if (option == "facebook")
    {
      url = this.facebookUrl;
    }
    else if (option == "instagram")
    {
      url = this.instagramUrl;
    }
    window.open(url,"_blank");
  }
}
