import { Component,Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-image-model',
  templateUrl: './image-model.component.html',
  styleUrls: ['./image-model.component.css']
})
export class ImageModelComponent {

  loading = true;
  constructor(
    public dialogRef: MatDialogRef<ImageModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
  onLoad(){
    setTimeout(()=> {
      this.loading = false;
    },100)
  }
}
