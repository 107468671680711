import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LocationComponent } from './components/location/location.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { MainBarComponent } from './components/main-bar/main-bar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {

  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import {ScrollSpyDirective} from '../app/services/scroll-spy.directive';
import { LayoutModule } from '@angular/cdk/layout';
import {GalleryPipe} from '../app/services/gallery-filter.pipe';
import { GalleryPhotosComponent } from './components/gallery/gallery-photos/gallery-photos.component';
import { DocumentsComponent } from './components/documents/documents.component';
import {Service} from './services/nav-bar.service';
import {TranslateService} from './services/translate.service';
import { FooterComponent } from './components/footer/footer.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import {ImageModelComponent} from './components/gallery/image-model/image-model.component';
import { CarouselComponent } from './components/gallery/carousel/carousel.component';
import { ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LocationComponent,
    GalleryComponent,
    ContactUsComponent,
    MainBarComponent,
    ScrollSpyDirective,
    GalleryPhotosComponent,
    GalleryPipe,
    DocumentsComponent,
    FooterComponent,
    SocialMediaComponent,
    ImageModelComponent,
    CarouselComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule, 
    AppRoutingModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    LayoutModule
  ],
  providers: [Service,TranslateService],
  bootstrap: [AppComponent],
  entryComponents:[ImageModelComponent]
})
export class AppModule { }
