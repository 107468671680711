import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gallery'
})
export class GalleryPipe implements PipeTransform {
  transform(items: any[],options: any[],counter:number): any[] {
    if (!items || !options)
    {
      return items;
    }
    let data = [];
    options.forEach(option=> {
      if (option.checked)
      {
        var _data = items.filter(i=> i.type == option.id);
        data = data.concat(_data);
      }
    });
    return data;
  }
}