import { Component, OnInit } from '@angular/core';
import {TranslateService} from '../../services/translate.service';
import {FormControl, FormGroupDirective, NgForm, Validators,FormGroup,FormBuilder} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {


 public myGroup: FormGroup;
 formSubmited = false;
 smile = true;
 alreadySend = 'false';
  matcher = new MyErrorStateMatcher();
  constructor(public translateService:TranslateService,private formBuilder: FormBuilder,private http: HttpClient) { }

  ngOnInit() {
    this.alreadySend = localStorage.getItem('user');
    if (this.alreadySend == 'true')
    {
      this.formSubmited = true;
    }
    this.myGroup = this.formBuilder.group({
      name: new FormControl ('',Validators.required),
      email : new FormControl('',Validators.compose( [Validators.required,Validators.email])),
      phone : new FormControl(''),
      message : new FormControl('', Validators.required),
      confirm: new FormControl('')
    });
    // setTimeout(()=> {
    //   this.smile = false;
    // },100)
  }
  
  submitForm(form)
  {

    if (form.confirm === '')
    {
      var l = localStorage.getItem('user');
      if (!localStorage.getItem('user'))
      {
        this.sendEmail(form).subscribe(response=> {
          this.formSubmited = true;
          setTimeout(()=> {
              this.smile = false;
           },1000)
        });  
        localStorage.setItem("user",'true');
      }
    }
  }

  sendEmail(form) {
    
    let url = `https://us-central1-insua-family-daycare-home.cloudfunctions.net/httpEmail`
  //  let url = 'http://localhost:5001/insua-family-daycare-home/us-central1/httpEmail';
    let params: URLSearchParams = new URLSearchParams();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Headers' : 'Content-Type',
      })
    };
    return this.http.post<any>(url,form, httpOptions);

  }

}
