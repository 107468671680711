import { Component, OnInit,ViewChild } from '@angular/core';
import {TranslateService} from '../../services/translate.service';
import {MatTabGroup} from '@angular/material'
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {
  selectedIndex = 0;
@ViewChild('tabs') tabGroup: MatTabGroup;

  constructor(public translateService:TranslateService) { }
    address = "4290 NW 21th Ave, Miami, Fl , 33142";
  // location = "This is a description of the address"
  // howTo = [{title:"From Airport",description:["Instruction 1","Instruction 2"]},
  // {title:"From Miami Beach",description:["Instruction 1","Instruction 2"]}]
  ngOnInit() {
    this.tabGroup.selectedIndex = 0
  }
  openMap(link)
  {
    window.open(link,"_blank");
  }
}
