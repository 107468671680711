import { Component, OnInit } from '@angular/core';
import {TranslateService} from '../../services/translate.service';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  showCarousel = true;
  counter = true;
  images = [
    // Dinning Room
    {title:'',cols:2,rows:2,url:'assets/images/daycare/dinning/IMG_4740.JPG',type:2},
    {title:'',cols:2,rows:2,url:'assets/images/daycare/dinning/IMG_4735.JPG',type:2},
    {title:'',cols:2,rows:2,url:'assets/images/daycare/dinning/IMG_4745.JPG',type:2},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/dinning/DSC00099_thumbail.jpg',type:2},
    {title:'',cols:1,rows:1,url:'assets/images/daycare/dinning/IMG_4709.JPG',type:2},

    //Classroom
    {title:'', cols:4,rows:4,url:'assets/images/daycare/salon/IMG_4702_thumbail.jpg', type:1},
    {title:'', cols:2,rows:2,url:'assets/images/daycare/salon/IMG_4705.JPG',type:1},
    {title:'', cols:2,rows:2,url:'assets/images/daycare/salon/IMG_4703.JPG',type:1},
    {title:'', cols:2,rows:2,url:'assets/images/daycare/salon/painting.jpg',type:1},
    {title:'', cols:1,rows:2,url:'assets/images/daycare/salon/playing.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/works.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00109_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00110_thumbail.jpg',type:1},
    {title:'', cols:1,rows:2,url:'assets/images/daycare/salon/DSC00113_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00119_thumbail.jpg',type:1},
    {title:'', cols:1,rows:2,url:'assets/images/daycare/salon/DSC00131_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00135_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00136_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00205_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00214_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00217_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00222_thumbail.jpg',type:1},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/salon/DSC00225_thumbail.jpg',type:1},


    //Backyard
    
    {title:'', cols:2,rows:2,url:'assets/images/daycare/outside/DSC00141_thumbail.jpg',type:3},
    {title:'', cols:2,rows:2,url:'assets/images/daycare/outside/DSC00167_thumbail.jpg',type:3},
    {title:'', cols:1,rows:2,url:'assets/images/daycare/outside/playingoutside.jpg',type:3},    
    {title:'', cols:1,rows:2,url:'assets/images/daycare/outside/DSC00175_thumbail.jpg',type:3},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/outside/DSC00156_thumbail.jpg',type:3},
    {title:'', cols:2,rows:2,url:'assets/images/daycare/outside/DSC00180_thumbail.jpg',type:3},
    {title:'', cols:1,rows:1,url:'assets/images/daycare/outside/DSC00181_thumbail.jpg',type:3},
    {title:'', cols:2,rows:2,url:'assets/images/daycare/outside/DSC00183_thumbail.jpg',type:3},
    {title:'', cols:1,rows:2,url:'assets/images/daycare/outside/DSC00187_thumbail.jpg',type:3},
    {title:'', cols:1,rows:2,url:'assets/images/daycare/outside/IMG_9983.JPG', type:3},
    {title:'', cols:2,rows:3,url:'assets/images/daycare/outside/DSC00227_thumbail.jpg',type:3},
    {title:'', cols:2,rows:2,url:'assets/images/daycare/outside/DSC00231_thumbail.jpg',type:3},
    {title:'', cols:2,rows:2,url:'assets/images/daycare/outside/DSC00241_thumbail.jpg',type:3}];
  constructor(public traslateService: TranslateService) { }

  ngOnInit() {
  }
  changeOption(option)
  {
   this.counter = !this.counter; 
  }

}
