import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import {TranslateService,Language} from '../../services/translate.service';
import {DomSanitizer} from '@angular/platform-browser';
interface Documents {
  title:string,
  description:string,
  imgUrl:string,
  cols:number,
  rows:number,
  documentUrl:string,
  preview:boolean,
  id:number
}
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})

export class DocumentsComponent implements OnInit {
  height = "200px";
  cols = 2;
  Languages = Language;
  selectedLanguage = this.translateService.getLanguageSelected();
  documents:Documents[] = [{id:1,title:"Family Daycare Contract",cols:1,rows:1,description:"This is the contract that the parent will sign in order for us to admit the child",
                            preview:false,documentUrl:"assets/documents/contract.pdf",imgUrl:"assets/images/logo/pdf.png"},
                            {id:2,title:"Disciplinary and Expulsion Form",cols:1,rows:1,description:"This is the discipinary form that the parent will sign in order for us to admit the child",
                            preview:false,documentUrl:"assets/documents/disciplinary.pdf",imgUrl:"assets/images/logo/pdf.png"}]
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      var data:Documents[] = [];
      this.translateService.getData().documents.document.forEach(image=> {
        if (matches)
        {
          image.cols = 2;
        }
        else
        {
          image.cols = 1;
        }
        data.push(image);
      });
      return data
    }));
  constructor(private breakpointObserver: BreakpointObserver,
              public translateService:TranslateService,
              public sanitizer: DomSanitizer) {                
              }

  ngOnInit() {
    if (this.selectedLanguage == this.Languages.English)
    this.translateService.selectedLanguageChange.subscribe(x=> {
      this.cards= this.breakpointObserver.observe(Breakpoints.Handset).pipe(
        map(({ matches }) => {
          var data:Documents[] = [];
          this.translateService.getData().documents.document.forEach(image=> {
            if (matches)
            {
              image.cols = 2;
            }
            else
            {
              image.cols = 1;
            }
            data.push(image);
          });
          return data
        }));
    });
  }
  documentUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  download (file)
  {
    window.open(file, '_blank');
  }
  tooglePreview(card:Documents)
  {
      card.preview = !card.preview;
      setTimeout(function() {
        if (card.preview)
        {
          card.rows = 3;
        }
        else
        {
          card.rows = 1;
        }
      },100)

  }
}
